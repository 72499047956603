import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Loadable from "@loadable/component"
import {MainSection,PortalSection,StepTitle,StepHint,VerticalSlider} from './UiComponents'
import { PhonePortal } from './PhonePortal';
import { updated } from '../utils/immutableUtils';
import {mobileAndDown} from '../utils/styleUtils';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTimes, faPlus, faChevronUp, faChevronDown} from "@fortawesome/free-solid-svg-icons";

const ContentEditor = typeof window === 'undefined' ? () => null : Loadable(() => import('./ContentEditor'));

const hintText = {
    code: "Highlight lines of code by clicking on their associated line number",
    content: "Add text or an image or video to this Tip Block",
    none: "",
}

export const StepThree = props => {
    const { values, setFieldValue, setErrors, children } = props
    const { code, codeLines, content } = values;
    const [currentIndex, setCurrentIndex] = useState(0);
    const [locks, setLocks] = useState(() => Array(codeLines.length).fill(true));
    const [hoveredField, setHoveredField] = useState('none');
    const prevStartingLine = currentIndex > 0 ? codeLines[currentIndex - 1][0] : 1;
    const [startingLine = 1, ...highlightedLines] = codeLines[currentIndex] || [prevStartingLine];

    const setLock = isLocked => setLocks(updated(locks, currentIndex, isLocked));
    const setStartingLine = line => setFieldValue('codeLines',updated(codeLines, currentIndex, [line, ...highlightedLines]));
    const setHighlightedLines = lines => {
        if (lines.length > highlightedLines) setLock(true); // Auto lock when a highlight is added
        setFieldValue('codeLines',updated(codeLines, currentIndex, [startingLine, ...lines]));
    }
    const deleteBlock = () => {
        if (content.length === 1) {
            setFieldValue('codeLines', [[1]]);
            setFieldValue('content', ['<p><br></p>']);
            setLocks([true]);
            return;
        }
        setFieldValue('codeLines', codeLines.filter((el, i) => i !== currentIndex));
        setFieldValue('content', content.filter((el, i) => i !== currentIndex));
        setLocks(locks.filter((el, i) => i !== currentIndex));
        setCurrentIndex(Math.max(currentIndex - 1, 0));
    }
    const addBlock = () => {
        setFieldValue('codeLines', [...codeLines.slice(0, currentIndex +1), [startingLine], ...codeLines.slice(currentIndex + 1)]);
        setFieldValue('content', [...content.slice(0, currentIndex +1), '<p><br></p>', ...content.slice(currentIndex + 1)]);
        setLocks([...locks.slice(0, currentIndex +1), true, ...locks.slice(currentIndex + 1)]);
        setCurrentIndex(currentIndex + 1);
    }

    return <>
        <MainSection>
            <div>
                <StepTitle>
                    Add Script & Line #
                </StepTitle>
                <StepHint>
                    Clearly describe what is happening in each section of your code
                    <br/>
                    <br/>
                    {hintText[hoveredField]}
                </StepHint>
            </div>
            {children}
        </MainSection>
        <StepThreePortalSection>
            <PortalContainer>
            <PhonePortal
                key={currentIndex}
                code={code}
                startingLine={startingLine}
                setStartingLine={setStartingLine}
                highlightedLines={highlightedLines}
                setHighlightedLines={setHighlightedLines}
                locked={locks[currentIndex]}
                setLocked={setLock}
                onMouseEnter={e => hoveredField !== 'code' ? setHoveredField('code') : null}
            >
                <ContentEditor
                    key={currentIndex}
                    content={content[currentIndex]}
                    onContentChange={chunk => setFieldValue('content', updated(content, currentIndex, chunk))}
                    onMouseEnter={e => hoveredField !== 'content' ? setHoveredField('content') : null}
                />
            </PhonePortal>
            </PortalContainer>
            <ButtonContainer>
                <Button disabled={currentIndex === 0} onClick={() => setCurrentIndex(currentIndex - 1)} type="button">
                    <Circle color="#222222">
                        <FontAwesomeIcon icon={faChevronUp} size="1x" color="white"/>
                    </Circle>
                </Button>
                <Button onClick={deleteBlock} type="button">
                    <Circle color="#E64173">
                        <FontAwesomeIcon icon={faTimes} size="1x" color="white"/>
                    </Circle>
                </Button>
                <Button onClick={addBlock} type="button">
                    <Circle color="#3B9AF8">
                        <FontAwesomeIcon icon={faPlus} size="1x" color="white"/>
                    </Circle>
                </Button>
                <Button disabled={currentIndex >= content.length - 1} onClick={() => setCurrentIndex(currentIndex + 1)} type="button">
                    <Circle color="#222222">
                        <FontAwesomeIcon icon={faChevronDown} size="1x" color="white"/>
                    </Circle>
                </Button>
            </ButtonContainer>
            <VerticalSlider value={currentIndex} onChange={setCurrentIndex} max={content.length - 1} />
        </StepThreePortalSection>
    </>
}

const Circle = styled.div`
    width: 2.5rem;
    height: 2.5rem;
    background-color: ${props=>props.color};
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
`

const ButtonContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    z-index: 5;
    ${mobileAndDown`
        grid-column: 1 / 2;
        grid-row: 1 / 2;
        position: relative;
    `}
`

const Button = styled.button`
    border: none;
    padding: 5px;
    background-color: transparent;
    transition: all 0.1s ease-in;
    &:focus {
        outline: none;
    }
    &:disabled > div {
        background-color: #C4C4C4;
    }
    &:hover:enabled {
        transform: scale(1.1);
    }
`
const StepThreePortalSection = styled(PortalSection)`
    ${mobileAndDown`
        display: grid;
        grid-template-rows: 1fr;
        grid-template-columns: 45px auto 45px;
        justify-content: center;
        align-items: center;
    `}
`

const PortalContainer = styled.div`
    grid-column: 2 / 3;
`