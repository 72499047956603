import {sanitize} from 'dompurify';
import {ErrorMessage} from 'formik';
import React, {useState} from 'react';
import FileUploader from '../components/FileUploader';
import {getCodeFromPlaygroundURL} from '../utils/api';
import {CodeToHtmlConverter} from './CodeToHtmlConverter';
import {PhonePortal} from './PhonePortal';
import {
    MainSection,
    PortalSection,
    RightSection,
    StepTitle,
    StepHint,
    StyledLoader,
    TipText
} from './UiComponents';

export const StepTwo = props => {
    const {children, values, setFieldValue, setErrors} = props
    const [loading, setLoading] = useState(false);
    return <>
        <MainSection>
            <StepTitle>
                Add Playground
            </StepTitle>
            <StepHint>
                Upload a playground file.
            </StepHint>
            <div>
                <FileUploader
                    name="playground"
                    onUpload={({url}) => {
                        setFieldValue('playground', url);
                        getCodeFromPlaygroundURL(values.id, url)
                            .then(code => setFieldValue('code', code))
                            .catch(() => setErrors('playground', 'Error uploading your playground file. Please try again'))
                            .then(() => setLoading('false'))
                    }}
                    onError={() => setErrors('playground', 'Error uploading your playground file. Please try again')}
                    setLoading={setLoading}
                />
                <ErrorMessage name="playground" component="div" />
                {children}
            </div>
        </MainSection>
        <PortalSection>
            <PhonePortal
                code={values.code}
                startingLine={0}
                highlightedLines={[]}
                readonly
                loading={loading}
            >
                <>
                    <StyledLoader type="TailSpin" color="#00BFFF" visible={loading} size={100} />
                    <TipText dangerouslySetInnerHTML={{__html: sanitize(values.content[0])}} />
                </>
            </PhonePortal>
            <RightSection>
            <   CodeToHtmlConverter code={values.code} onChange={codeHtml => setFieldValue('codeHTML', codeHtml)} />
            </RightSection>
        </PortalSection>
    </>
}