import {sanitize} from 'dompurify';
import React, {useEffect, useMemo, useRef, useState} from 'react';
import SyntaxHighlighter from 'react-syntax-highlighter';
import {monokaiSublime} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import styled from 'styled-components';
import {CodeSection, Portal, TipText, VerticalSlider} from './UiComponents';
import {mobileAndDown, tabletAndDown} from '../utils/styleUtils';

const PX_PER_LINE = 13.5; // font-size (9) * line-height (1.5)

const scrollTo = (ref, top) => {
    if (ref.current) {
        ref.current.scrollTo({
            left: 0,
            top,
            behavior: 'smooth',
        })
    }
}

const makeIndexMap = startingLines => {
    const indexMap = {};
    startingLines.forEach((line, index) => {
        indexMap[line] ? indexMap[line].push(index) : indexMap[line] = [index];
    });
    return indexMap;
}

const getStartingPositions = ref => {
    if (!ref.current) return [0];
    return Array.from(ref.current.children).map((el, i, all) => el.offsetTop - all[0].offsetTop);
}

export const DualScrollPortal = props => {
    const {
        code,
        content,
        codeLines,
    } = props;
    const containerDOM = useRef();
    const tipContainer = useRef();
    const ignoreScrolling = useRef(false);
    const prevTipTop = useRef(0);
    const prevCodeTop = useRef();
    const timeoutRef = useRef();
    const startingLines = useMemo(() => codeLines.map(([line]) => line), [codeLines]);
    const _highlightedSet = useMemo(() => new Set(codeLines.reduce((acc, [_, ...lines]) => acc.concat(lines), [])), [codeLines]);
    const [currentIndex, setCurrentIndex] = useState(0);
    const startingLine = startingLines[currentIndex];
    const indexMap = useMemo(() => makeIndexMap(startingLines), [startingLines]);
    const tipBlockStartingPositions = useMemo(() => getStartingPositions(tipContainer), [tipContainer.current && tipContainer.current.scrollHeight])

    useEffect(() => {
        document.querySelectorAll("video").forEach(element => element.removeAttribute('autoplay'))
        ignoreScrolling.current = true;
        scrollTo(containerDOM, (startingLine - 1) * PX_PER_LINE);
        scrollTo(tipContainer, tipBlockStartingPositions[currentIndex]);
        timeoutRef.current = setTimeout(() => ignoreScrolling.current = false, 200);
        return () => clearTimeout(timeoutRef.current);
    }, [currentIndex]);

    const getLineClassName = i => _highlightedSet.has(i) ? {className: 'selected-row'} : {};

    const handleCodeScroll = e => {
        const {scrollTop} = e.target;
        const currentLine = Math.round(scrollTop / PX_PER_LINE) + 1;
        const correspondingTips = indexMap[currentLine]
        const direction = scrollTop - prevCodeTop.current;
        prevCodeTop.current = scrollTop;
        if (!correspondingTips || ignoreScrolling.current) return;
        const nearestCorrespondingTip = correspondingTips.reduce((closest, index) => {
            const proximity = index - currentIndex;
            if (proximity < closest - currentIndex) {
                return index;
            } else if (proximity > closest - currentIndex) {
                return closest;
            } else {
                // They are the same proximity. Go in the direction of scroll
                direction > 0 ? Math.max(closest, index) : Math.min(closest, index);
            }
        });
        if (nearestCorrespondingTip !== currentIndex) {
            setCurrentIndex(nearestCorrespondingTip);
        }
    }

    const handleTipScroll = e => {
        const {scrollTop} = e.target;
        const direction = scrollTop - prevTipTop.current;
        const topOfCurrentTip = tipBlockStartingPositions[currentIndex];
        const GAP = 30;
        if (direction > 0 && scrollTop > topOfCurrentTip + GAP && currentIndex < content.length - 1) {
            // Moving down
            setCurrentIndex(currentIndex + 1);
        } else if (direction < 0 && scrollTop < topOfCurrentTip - GAP && currentIndex > 0) {
            // Moving up
            setCurrentIndex(currentIndex - 1);
        }
        prevTipTop.current = scrollTop;
    }

    return <>
        <Portal>
            <CodeSection onScroll={handleCodeScroll} ref={containerDOM} readonly>
                <SyntaxHighlighter
                    language="swift"
                    showLineNumbers
                    style={monokaiSublime}
                    wrapLines
                    lineProps={getLineClassName}
                >
                    {code}
                </SyntaxHighlighter>
            </CodeSection>
            <TipContainer onScroll={handleTipScroll} ref={tipContainer}>
                {content.map((block, i) => <TipBlock key={i} dangerouslySetInnerHTML={{__html: sanitize(block)}} />)}
            </TipContainer>
        </Portal>
    </>
}

const TipBlock = styled(TipText)`
    height: auto;
    min-height: 258px;
    border-radius: 0;
    video, img {
        margin: 0 -8px;
        width: 100%;
    }
    p, ol, ul, pre, blockquote, h1, h2, h3, h4, h5, h6 {
        margin: 0;
        padding: 0;
        counter-reset: list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
    }
`;

const TipContainer = styled(TipText)`
    overflow-y: scroll;
    width: 284px;
    height: 258px;
    margin-left: 7px;
    padding: 0;
    ${mobileAndDown`
        height: 208px;
        width: 230px;
    `}
`