import React, {useState} from "react";
import styled from "styled-components";

import {
  MainSection,
  PortalSection,
  StepTitle,
  StepHint,
  MobileSeparator,
} from "./UiComponents";
import { DualScrollPortal } from "./DualScrollPortal";
import { PricePicker } from "./PricePicker";

export const StepFour = (props) => {
  const { values, setFieldValue, children, enums } = props;
  const { code, codeLines, content, questionPrice } = values;

  return (
    <>
      <MainSection>
        <div>
          <StepTitle>Review & Price Tip</StepTitle>
          <CustomStepHint>
            How much would you like to charge to answer questions for this Tip?
          </CustomStepHint>
          <PricePicker
            prices={enums.SwiftTips_IAP_PriceTier}
            value={questionPrice}
            onChange={(price) => setFieldValue("questionPrice", price)}
          />
        </div>
        {children}
      </MainSection>
      <MobileSeparator />
      <PortalSection>
        <DualScrollPortal code={code} content={content} codeLines={codeLines} />
      </PortalSection>
      
    </>
  );
};

const CustomStepHint = styled(StepHint)`
  font-size: 24px;
  color: #cfc7c7;
`;

