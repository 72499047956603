import React, {useEffect, useRef} from 'react';
import SyntaxHighlighter from 'react-syntax-highlighter';
import {monokaiSublime} from 'react-syntax-highlighter/dist/esm/styles/hljs';

export const CodeToHtmlConverter = ({code, onChange}) => {
    const containerRef = useRef();
    useEffect(() => {
        if (code && containerRef.current) {
            containerRef.current.innerHTML = code;
        }
    }, [code]);

    return <div style={{display: 'none'}} ref={containerRef}>
        <SyntaxHighlighter language="swift" style={monokaiSublime} lineProps={() => ({style: {display: 'block'}})}>
            {code}
        </SyntaxHighlighter>
    </div>
}