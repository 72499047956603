import React, { useState, useRef, useEffect } from 'react'
import styled from 'styled-components'
import { ErrorMessage } from 'formik';
import { CompactPicker } from 'react-color'
import {mobileAndDown} from '../utils/styleUtils';

import {
    Portal,
    MainSection,
    PortalSection,
    Background,
    RightSection,
    StepTitle,
    StepHint,
    Card,
    Label,
    Slider,
 } from './UiComponents'
import { ImagePicker } from './ImagePicker';

const hintText = {
    title: "Write a title that clearly explains what your Tip is about (six words or less).",
    subtitle: "Write a subtitle that details in one sentence what the Tip illustrates.",
    introduction: "Write 2-3 sentences explaining in more detail what a user will learn.",
}

export const StepOne = props => {
    const { children, values, setFieldValue } = props
    const backgroundRef = useRef();
    const rightSection = useRef();
    const [focusedField, setFocusedField] = useState('title');
    const [focusedSection, setFocusedSection] = useState(null);
    const [hoveredField, setHoveredField] = useState('title');

    const setFocus = sectionName => {
        if (focusedSection !== sectionName) setFocusedSection(sectionName);
        if (focusedField !== sectionName && (sectionName === 'title' || sectionName === 'subtitle')) {
            setFocusedField(sectionName);
        }
    }

    useEffect(() => {
        const handleClick = e => {
            if (!backgroundRef.current.contains(e.target) && !rightSection.current.contains(e.target)) {
                setFocus(null);
            }
        };
        document.addEventListener("click", handleClick);
        return () => document.removeEventListener("click", handleClick);
    }, [focusedSection]);

    return <>
            <MainSection>
                <div>
                    <StepTitle>
                        Customize your Tip
                    </StepTitle>
                    <StepHint>
                        {hintText[document.activeElement.name] || hintText[hoveredField]}
                    </StepHint>
                    {children}
                </div>
            </MainSection>
            <PortalSection>
                <Portal>
                    <Background
                        id="background"
                        tabindex={1}
                        ref={backgroundRef}
                        image={values.headerImage}
                        onClick={e => e.target.id === 'background' && setFocus('background')}
                    >
                        <TextInput
                            name="title"
                            value={values.title}
                            color={values.titleLabelRGB}
                            fontSize={values.titleLabelFontSize}
                            onChange={e => setFieldValue('title', e.target.value)}
                            onFocus={() => setFocus('title')}
                            onMouseEnter={e => hoveredField !== 'title' ? setHoveredField('title') : null}
                            rows="2"
                        />
                        <TextInput
                            name="subtitle"
                            value={values.subtitle}
                            color={values.subtitleLabelRGB}
                            fontSize={values.subtitleLabelFontSize}
                            onChange={e => setFieldValue('subtitle', e.target.value)}
                            onFocus={() => setFocus('subtitle')}
                            onMouseEnter={e => hoveredField !== 'subtitle' ? setHoveredField('subtitle') : null}
                            rows="2"
                        />
                        <TextInput
                            name="introduction"
                            value={values.introduction}
                            color={[0,0,0]}
                            fontSize={16}
                            onChange={e => setFieldValue('introduction', e.target.value)}
                            onFocus={() => setFocus('introduction')}
                            onMouseEnter={e => hoveredField !== 'introduction' ? setHoveredField('introduction') : null}
                            rows="2"
                        />
                    </Background>
                </Portal>
                <ResponsiveRightSection showing={focusedSection} ref={rightSection}>
                    <Card id="font_picker">
                        <div>
                            <Label htmlFor={`${focusedField}LabelFontSize`}>Font Size</Label>
                            <Slider type="range" min="12" max="40" name={`${focusedField}LabelFontSize`} />
                            <ErrorMessage name={`${focusedField}LabelFontSize`} component="div" />
                        </div>
                        <div>
                            <Label htmlFor={`${focusedField}LabelRGB`}>Font Color</Label><br></br>
                            <CompactPicker
                                color={{ r: values[`${focusedField}LabelRGB`][0], g: values[`${focusedField}LabelRGB`][1], b: values[`${focusedField}LabelRGB`][2] }}
                                onChangeComplete={({ rgb: { r, g, b } }) => setFieldValue(`${focusedField}LabelRGB`, [r, g, b])}
                            />
                        </div>
                    </Card>
                    <ImagePicker onImageSelect={image => setFieldValue('headerImage', image)}/>
                </ResponsiveRightSection>
            </PortalSection>
    </>
}

const TextInput = styled.textarea`
    font-family: SF Pro Display, Suisse-Regular;
    background-color: transparent;
    border: none;
    margin: 25px 0 35px 0;
    padding: 10px 5px 10px 5px;
    font-size: ${({ fontSize }) => fontSize}px;
    color: rgb(${({ color }) => (color || []).join(',')});
    width: 273px !important;
    min-height: 100px !important;
    resize: none;
    ${mobileAndDown`
        width: 222px !important;
        padding: 5px 5px 5px 5px;
        margin: 15px 0 20px 0;
    `}
`

const ResponsiveRightSection = styled(RightSection)`
    @media screen and (max-width: 797px) {
        position: absolute;
        width: 350px;
        height: max-content;
        left: 70px;
        bottom: 0;
        ${({ showing }) => showing === 'background' ? `
            > #font_picker { display: none }
            width: calc(100vw - 55px);
            right: 0;
            top: 350px;
        ` : showing === 'title' ? `
            > #image_picker { display: none }
            top: 180px;
        ` : showing === 'subtitle' ? `
            > #image_picker { display: none }
            top: -30px;
        ` : `
            > #font_picker { display: none }
            > #image_picker { display: none }
        `}
    }
`