import xor from 'lodash/xor';
import React, {useEffect, useRef} from 'react';
import SyntaxHighlighter from 'react-syntax-highlighter';
import {monokaiSublime} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import {LockButton} from './LockButton';
import {CodeSection, Portal} from './UiComponents';

const PX_PER_LINE = 13.5; // font-size (10) * line-height (1.5)

export const PhonePortal = props => {
    const {
        code,
        readonly,
        startingLine,
        setStartingLine,
        highlightedLines,
        setHighlightedLines,
        locked,
        setLocked,
        children,
        ...otherProps
    } = props;
    const timeoutRef = useRef();
    const containerDOM = useRef();
    useEffect(() => {
        if (containerDOM.current) {
            containerDOM.current.scrollTo(0, (startingLine - 1) * PX_PER_LINE);
        }
        return () => clearTimeout(timeoutRef.current);
    }, [startingLine]);

    const _highlightedSet = new Set(highlightedLines);
    const getLineClassName = i => _highlightedSet.has(i) ? {className: 'selected-row'} : {};

    const handleLineSelection = e => {
        if (readonly || e.target.className.indexOf('react-syntax-highlighter-line-number') === -1) return;
        setHighlightedLines(xor(highlightedLines, [parseInt(e.target.innerText)]).sort((a, b) => a - b));
    }

    const handleScroll = e => {
        const {scrollTop} = e.target;
        clearTimeout(timeoutRef.current);
        timeoutRef.current = setTimeout(() => !readonly && setStartingLine(Math.round(scrollTop / PX_PER_LINE) + 1), 500);
    }

    return <Portal>
        <CodeSection onScroll={handleScroll} ref={containerDOM} locked={locked} readonly={readonly} {...otherProps}>
            {readonly ? null : <LockButton locked={locked} onToggle={setLocked} />}
            <SyntaxHighlighter
                language="swift"
                style={monokaiSublime}
                showLineNumbers
                wrapLines
                onMouseDown={handleLineSelection}
                lineProps={getLineClassName}
            >
                {code}
            </SyntaxHighlighter>
        </CodeSection>
        {children}
    </Portal>
}