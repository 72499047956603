import React from 'react'
import styled from 'styled-components'
export const LockButton = ({ locked, onToggle }) => {
    return <Button onClick={() => onToggle(!locked)}>
        {locked ? (
            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
                <title>lock-rounded</title>
                <path d="M16 21.915v0c-0.583-0.206-1-0.762-1-1.415 0-0.828 0.672-1.5 1.5-1.5s1.5 0.672 1.5 1.5c0 0.653-0.417 1.209-1 1.415v2.594c0 0.263-0.224 0.491-0.5 0.491-0.268 0-0.5-0.22-0.5-0.491v-2.594zM9 14v0 0c-1.658 0.005-3 1.34-3 3.009v4.991c0 4.418 3.586 8 7.999 8h5.002c4.418 0 7.999-3.591 7.999-8v-4.991c0-1.659-1.341-3.005-3-3.009v-3.501c0-4.142-3.366-7.499-7.5-7.499-4.142 0-7.5 3.357-7.5 7.499v3.501zM12 14v-3.499c0-2.492 2.015-4.501 4.5-4.501 2.48 0 4.5 2.015 4.5 4.501v3.499h-9z"></path>
            </svg>
        ) : (
            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
                <title>lock-rounded-open</title>
                <path d="M16 23.915v0 0c-0.583-0.206-1-0.762-1-1.415 0-0.828 0.672-1.5 1.5-1.5s1.5 0.672 1.5 1.5c0 0.653-0.417 1.209-1 1.415v2.594c0 0.263-0.224 0.491-0.5 0.491-0.268 0-0.5-0.22-0.5-0.491v-2.594zM24 9.5v-1.001c0-4.142-3.366-7.499-7.5-7.499-4.142 0-7.5 3.357-7.5 7.499v7.501c-1.658 0.005-3 1.34-3 3.009v4.991c0 4.418 3.586 8 7.999 8h5.002c4.418 0 7.999-3.591 7.999-8v-4.991c0-1.659-1.341-3.005-3-3.009h-11.973c-0.018-0.164-0.027-0.331-0.027-0.499v-7.001c0-2.489 2.015-4.499 4.5-4.499 2.48 0 4.5 2.014 4.5 4.499v3.624c0 1.036 0.666 1.876 1.5 1.876 0.828 0 1.5-0.848 1.5-1.876v-2.624z"></path>
            </svg>
        )}
    </Button>
}

const Button = styled.button`
    position: absolute;
    right: 16px;
    top: 56px;
    background: transparent;
    border: none;
    outline: none;
    cursor: pointer;
    transform-origin: right;
    transition: transform 0.2s;
    z-index: 10;
    &:hover {
        transform: scale(1.2);
    }
    svg {
        fill: #007AFF;
    }
`