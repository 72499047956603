import React from 'react';
import styled from 'styled-components'
import debounce from 'lodash/debounce'
import isEqual from 'lodash/isEqual'
import { formatDistanceStrict } from 'date-fns'

export class AutoSave extends React.Component {
  state = { isSaving: false }
  
  componentWillReceiveProps(nextProps) {
      if (!isEqual(nextProps.values, this.props.values)) {
        this.save()
      }
  }

  componentWillUnmount() {
    clearTimeout(this.timeoutRef);
  }

  save = debounce(() => {
    this.setState({ isSaving: true, saveError: undefined })
    this.props.onSave(this.props.values) 
      .then(() => this.setState({ isSaving: false, lastSaved: new Date(), saveError: null }))
      .catch(saveError => this.setState({ isSaving: false, saveError }))
    }, 1000)

  render() {
    clearTimeout(this.timeoutRef);
    this.timeoutRef = setTimeout(() => {
      this.setState(({ tick = 0 }) => ({ tick: tick + 1 }));
    }, 30000);
    const text = this.state.isSaving ? 'Saving...' : (this.state.lastSaved && `Last saved ${formatDistanceStrict(
        this.state.lastSaved,
        new Date(),
        { addSuffix: true, unit: 'minute' },
    ).replace('0 minutes ago', 'just now')}`)

    return <StyledAutoSave>
      {this.state.saveError ? <><span style={{color: 'red'}}>{this.state.saveError && 'Error while saving your draft tip'}</span><br/></> : null}
      {text}
    </StyledAutoSave>
  }
};

const StyledAutoSave = styled.span`
  display: inline-block;
  font-family: Suisse-Regular;
  font-size: 12px;
  padding: 0 0 15px 0;
`

