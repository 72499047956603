import { useQuery } from "@apollo/react-hooks";
import { Form, Formik } from 'formik';
import { graphql, navigate } from 'gatsby';
import gql from 'graphql-tag';
import isEmpty from "lodash/isEmpty";
import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { AutoSave } from '../components/AutoSave';
import Layout from '../components/Layout';
import { StepFour } from '../components/StepFour';
import { StepOne } from '../components/StepOne';
import { StepThree } from '../components/StepThree';
import { StepTwo } from '../components/StepTwo';
import { SubmitModal } from '../components/SubmitModal';
import { Button, Progress } from '../components/UiComponents';
import withLocation from '../components/withLocation';
import {host} from '../constants';
import {getUserFromCookie} from "../utils/auth";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronLeft, faChevronRight} from "@fortawesome/free-solid-svg-icons";
import {LOCALSTORAGE_KEY_REDIRECT_URL} from "../utils/localstorage";
import { toRem, mobileAndDown } from "../utils/styleUtils";


const getDraftTipQuery = gql`
query getDraftTip($draftTipID: ID!) {
  getDraftTip(id: $draftTipID) {
    id
    title
    subtitle
    introduction
    headerImage
    playground
    titleLabelRGB
    subtitleLabelRGB
    titleLabelFontSize
    subtitleLabelFontSize
    code
    content
    swiftVersion
    codeLines
    codeHTML
    frameworks
    authorID
    searchTerms
    readyForReview
    questionPrice
  }
}
`

const handleSubmit = ({ __typename, ...values }, { setSubmitting }) => {
  return fetch(`${host}/draft/tip`, {
    method: 'PUT',
    cache: 'no-cache',
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/json'
    },
    redirect: 'follow',
    referrer: 'no-referrer',
    body: JSON.stringify(values)
  }).then(res => {
    setSubmitting(false)
  }).catch(err => {
    console.error(err)
    setSubmitting(false)
  })
}

const isInvalid = (values, step) => step === 1 && !values.playground;

const DraftTip = (props) => {
  const [step, setStep] = useState(0);
  const [user, setUser] = useState({});

  const { params: { creatorID, draftTipID }, data: metadata } = props;

  const enums = useMemo(() => metadata.__schema.types
    .filter(({ kind, name }) => kind === 'ENUM' && name && name.indexOf('SwiftTips_') === 0)
    .reduce((obj, { name, enumValues }) => { obj[name] = enumValues.map(({ name }) => name); return obj; }, {}),
    []
  );

  useEffect(() => {
    const user = getUserFromCookie();
    if (isEmpty(user)) {
      localStorage.setItem(LOCALSTORAGE_KEY_REDIRECT_URL, props.location.pathname + props.location.search);
      navigate('/login')
      return;
    }

    setUser(user);
    console.log('User Found - ', user);
  }, [])

  const { data, loading, error } = useQuery(getDraftTipQuery, { variables: { draftTipID } });
  const initialValues = data && data.getDraftTip;

  if (isEmpty(user) || !initialValues) {
    return <div />
  } else if (error) {
    console.error("Error while fetching the Tip Data - ", error);
  } else if (loading) {
    return <div>Loading...</div>;
  }

  const WizardStep = [
    StepOne,
    StepTwo,
    StepThree,
    StepFour,
    StepFour,
  ][step]

  return (
    <Layout navColor="white" background="white" toggled={false}>
      <Formik initialValues={initialValues} onSubmit={handleSubmit}>
        {({ values, setFieldValue, setErrors, submitForm }) => (
          <StyledForm>
            <DraftWizard>
              <WizardStep values={values} setFieldValue={setFieldValue} setErrors={setErrors} enums={enums}>
                <AutoSave onSave={submitForm} values={values} />
              </WizardStep>
              <ButtonContainer>
                {step > 0 ? <TextButton type="button" onClick={e => { e.preventDefault(); setStep(step - 1) }}>
                  <span style={{ margin: "0 10px 0 0" }}><FontAwesomeIcon icon={faChevronLeft} size="xs" /></span>Prev Step
            </TextButton> : <TextButton type="button" disabled>
                    <span style={{ margin: "0 10px 0 0" }}><FontAwesomeIcon icon={faChevronLeft} size="xs" /></span>Prev Step
            </TextButton>}
                {step < 3 ?
                  <TextButton type="button" onClick={e => { e.preventDefault(); setStep(step + 1) }}>
                    Next Step<span style={{ margin: "0 0 0 10px" }}><FontAwesomeIcon icon={faChevronRight} size="xs" /></span>
                  </TextButton> :
                  <TextButton type="button" onClick={e => { e.preventDefault(); setStep(step + 1) }} id="review">
                    Submit For Review
          </TextButton>}
              </ButtonContainer>
            </DraftWizard>
            <Progress step={step} />
            <SubmitModal
              open={step === 4}
              onClose={() => setStep(step - 1)}
              onSave={() => { setFieldValue('readyForReview', true); return submitForm(); }}
            />
          </StyledForm>)}
      </Formik>
    </Layout >
  );
}

export default withLocation(DraftTip)

export const query = graphql`
query metadata {
  __schema {
    types {
      kind
      name
      enumValues {
        name
      }
    }
  }
}`

const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
  ${mobileAndDown`
    margin-left: 0;
    margin-right: 0;
  `}
  input, select, option {
    height: 1.5rem;
    padding: 0 0.5rem;
    margin-right: 2rem;
    border-radius: 5px;
    width: 180px;
    font-family: Circular-Book;
  }
  textarea {
    min-height: 13rem;
    width: 45%;
  }
  div, pre {
    /* width */
    &::-webkit-scrollbar {
      width: 4px !important;
      height: 4px !important;
    }
    
    /* Track */
    &::-webkit-scrollbar-track {
      background: transparent !important;
    }
    
    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: rgb(128, 128, 128, 0.2) !important;
      cursor: pointer;
    }
    
    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: rgb(128, 128, 128, 0.5) !important;
    }
  }
`
const DraftWizard = styled.div`
  display: grid;
  grid-template-rows: auto 15vh;
  grid-template-columns: 1fr 1fr;
  ${mobileAndDown`
    grid-template-rows: auto auto auto;
    grid-template-columns: 1fr;
  `}
`
const ButtonContainer = styled.div`
  grid-row: 2 / 3;
  grid-column: 1 / 2;
  padding: 0 0 2rem 4rem;
  ${mobileAndDown`
    grid-row: 3 / 4;
    padding: 0;
    margin: 0 auto;
    padding: 0;
  `}
`

const TextButton = styled.button`
  display: inline-block;
  font-family: Circular-Book;
  border: none;
  background-color: transparent;
  color: #3B9AF8;
  font-size: ${toRem(14)};
  padding: 0.2rem 0.5rem;
  border-radius: 7px;
  border: 2px solid #3B9AF8;
  cursor: pointer;
  transition: 0.5s;
  margin: 1rem;
  margin-left: 0;
  ${mobileAndDown`
    font-size: 18px;
  `}
  &:focus {
    outline:0;
  }
  &:hover {
    background-color: #3B9AF8;
    color: white;
  }
  &:disabled {
    color: #C4C4C4;
    cursor: auto;
    border: 2px solid #C4C4C4;
    &:hover {
      background-color: transparent;
      color: #C4C4C4
    }
  }
  &#review {
    background-color: #3B9AF8;
    color: white;
    &:hover {
      opacity: 0.8;
    }
  }
`

