import React from 'react';
import styled from 'styled-components';
import { Card, Label } from './UiComponents';

const imageNumbers = Array(132).fill().map((_, i) => i + 1);
const thumbnail = num => `https://cf.swifttips.co/tip-images-website/thumbnails/ST-${num}.png`
const fullImage = num => `https://cf.swifttips.co/tip-images-website/ST-${num}.jpg`
export const ImagePicker = ({ onImageSelect }) => <Anchor id="image_picker">
    <ImagePickerCard>
        <Label htmlFor="headerImage">Image Selector</Label><br></br>
        <ImagesContainer>
            {imageNumbers.map(num => <ImageContainer key={num} onClick={() => onImageSelect(fullImage(num))}>
                <img src={thumbnail(num)}/>
            </ImageContainer>)}
        </ImagesContainer>
    </ImagePickerCard>
</Anchor>


const Anchor = styled.div`
    position: relative;
    height: 200px;
`

const ImagePickerCard = styled(Card)`
    position: absolute;
    right: 0;
    z-index: 1;
`

const ImagesContainer = styled.div`
    display: flex;
    border-radius: 10px;
    flex-wrap: wrap;
    justify-content: space-evenly;
    height: 100px;
    min-width: 255px;
    overflow-y: scroll;
    background: white;
    transition: min-width 0.3s ease-in-out, height ease-in-out 0.3s;
    &:hover {
        min-width: calc(50vw - 13rem);
        height: 210px;
    }
`

const ImageContainer = styled.div`
    height: 93px;
    width: 75px;
    margin: 3px;
    overflow: hidden;
    cursor: pointer;
    border: 2px solid transparent;
    border-radius: 10px;
    img {
        width: 100%;
        height: auto;
    }
    transition:
        height 0.1s ease-in,
        width 0.1s ease-in,
        margin 0.1s ease-in;
    &:hover {
        height: 99px;
        width: 81px;
        margin: 0;
        border: 2px solid rgb(256,256,256,0.3);
    }
`